import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/System/My_Account/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemChangeUserDataPerm';
import AccountTable from 'components/Web_User_Interface/1440p_Series/System/My_Account/AccountTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "System Menu // My Account",
  "path": "/Web_User_Interface/1440p_Series/System/My_Account/",
  "dateChanged": "2023-01-18",
  "author": "Mike Polinowski",
  "excerpt": "Web User Interface Settings",
  "image": "./WebUI_1440p_SearchThumb_System_WebUI.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_System_WebUI.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='System Menu // My Account' dateChanged='2023-01-18' author='Mike Polinowski' tag='INSTAR IP Camera' description='Web User Interface Settings' image='/images/Search/WebUI_1440p_SearchThumb_System_WebUI.png' twitter='/images/Search/WebUI_1440p_SearchThumb_System_WebUI.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/System/My_Account/' locationFR='/fr/Web_User_Interface/1440p_Series/System/My_Account/' crumbLabel="WebUI" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "system-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#system-menu",
        "aria-label": "system menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`System Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0705bf1a889c9fead963d7b33c329e81/e4a12/1440p_Settings_System_My_Account.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACIklEQVQoz22R204TURRA+xkSExNC6HXaCnSK7Vza6XQotLSd6cycdorRSIyJxASEBjSClxgg8AFigpEHFF/5w2XOoCFEH1b2OS8ra2cnCoUCipInnZxhsenT3b9meXJFa3LF0s4Vze2f2Nty3iL/q3u/EB+v6Yh1Hty/x9TUFNPT0yRyuRy5nEJqdoaK3ePJhx+M9s6J3n6LCXfP8Xa+4r7+codwcsbjd9+xOwIlk0VRiuTzRRLZbBZJOpOhtDCHGHTx+23c1Rb9Tot+26GulamW56ioD6n+YXGhgF3TsK0mhm5j1R00rfa3MEc6nUZVy0TjNUZRRBgKvIGP5w2w7SaGWaOq6WgSXadS1VhqLbPSalOvWdTMBtWKcStMpVKUSiWiKGIoBL7v43oeo9GIs7MzLi4u7nB5ecnBwXvcrs2SY2Ea9r9CVVVjoRCCwWCA694IDw8POT095fj4OObo6IiTkxM2N7cYiQYtp4ZpNNC1/xSOx+MYKZKEYYjjODQdJ169IbEsOisrhEGAcG3seg2nYWHoOolMJnNHKCWycDgcxm9ZKtf3vR5Bv4PvdekJgbs2JowiNoY9HMuMhVpVJyGPoShKfJRyuRyLpCAIghhZGAY+4foW4tVnhs93edNz+dTz2OgP2H8WErQbmKYUGjeF+XweOYvFIvV6HdM00TTtlmoFbfUpRrSH2X/By3mVycIiI/URpfkChWyS2WSKZDLFb+5SfEM+e/OtAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0705bf1a889c9fead963d7b33c329e81/e4706/1440p_Settings_System_My_Account.avif 230w", "/en/static/0705bf1a889c9fead963d7b33c329e81/d1af7/1440p_Settings_System_My_Account.avif 460w", "/en/static/0705bf1a889c9fead963d7b33c329e81/7f308/1440p_Settings_System_My_Account.avif 920w", "/en/static/0705bf1a889c9fead963d7b33c329e81/43a10/1440p_Settings_System_My_Account.avif 963w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0705bf1a889c9fead963d7b33c329e81/a0b58/1440p_Settings_System_My_Account.webp 230w", "/en/static/0705bf1a889c9fead963d7b33c329e81/bc10c/1440p_Settings_System_My_Account.webp 460w", "/en/static/0705bf1a889c9fead963d7b33c329e81/966d8/1440p_Settings_System_My_Account.webp 920w", "/en/static/0705bf1a889c9fead963d7b33c329e81/095e2/1440p_Settings_System_My_Account.webp 963w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0705bf1a889c9fead963d7b33c329e81/81c8e/1440p_Settings_System_My_Account.png 230w", "/en/static/0705bf1a889c9fead963d7b33c329e81/08a84/1440p_Settings_System_My_Account.png 460w", "/en/static/0705bf1a889c9fead963d7b33c329e81/c0255/1440p_Settings_System_My_Account.png 920w", "/en/static/0705bf1a889c9fead963d7b33c329e81/e4a12/1440p_Settings_System_My_Account.png 963w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0705bf1a889c9fead963d7b33c329e81/c0255/1440p_Settings_System_My_Account.png",
              "alt": "Web User Interface - 1440p Series - System - My Account",
              "title": "Web User Interface - 1440p Series - System - My Account",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <AccountTable mdxType="AccountTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      